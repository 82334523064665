export const METHOD_EMAIL = "EMAIL";
export const METHOD_PHONE = "PHONE";
export const METHOD_SMS = "SMS";
export const METHOD_ONLINE_ORDER = "ONLINE_ORDER";
export const METHOD_INTERNAL = "INTERNAL";
export const METHODS = [
  METHOD_EMAIL,
  METHOD_PHONE,
  METHOD_SMS,
  METHOD_ONLINE_ORDER,
  METHOD_INTERNAL,
];
