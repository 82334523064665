<template>
  <el-select
    v-model="supplierModel"
    @change="supplierChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.SUPPLIER')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    :value="[1]"
    remote
    :remote-method="getSuppliers"
    :loading="loading"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL_SUPPLIERS')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="supplier in suppliers"
      :key="supplier.id"
      :value="supplier.id"
      :label="`${supplier.company_name} - ${supplier.email}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "supplier-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    suppliersArray: {
      type: Array,
      default: () => [],
      description: "Suppliers",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    supplier: {
      type: String,
      default: null,
      description: "Supplier id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterWarehouse: {
      type: String,
      default: null,
      description: "Warehouse id",
    },
    filterWarehouseBuyingPriceNotNull: {
      type: String,
      default: null,
      description: "Warehouse id",
    },
    filterIdsNotIn: {
      type: Array,
      default: () => [],
      description: "Id not in",
    },
    filterActive: {
      type: Boolean,
      default: true,
      description: "",
    },
  },

  data() {
    return {
      supplierModel: this.supplier ?? null,
      suppliers: {},
    };
  },

  setup() {},

  created() {
    this.getSuppliers(null, this.supplier);
  },

  mounted() {},

  methods: {
    async getSuppliers(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "company_name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };
        
        if (this.filterActive) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              active: 1,
            },
          };
        }
        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }
        if (this.filterIdsNotIn.length > 0) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              idsNotIn: this.filterIdsNotIn,
            },
          };
        }
        if (this.filterWarehouse) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              warehouse: this.filterWarehouse,
            },
          };
        }
        if (this.filterWarehouseBuyingPriceNotNull) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              warehouseBuyingPriceNotNull: this.filterWarehouseBuyingPriceNotNull,
            },
          };
        }

        await this.$store.dispatch("suppliers/list", params);
        const suppliersArr = await this.$store.getters["suppliers/list"];
        this.suppliers = {};
        suppliersArr.forEach((supplier) => {
          this.suppliers[supplier.id] = supplier;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    supplierChanged(supplier) {
      if (this.multiple) {
        supplier = [...supplier].map((item) => ({
          type: "suppliers",
          id: item,
        }));
      }
      this.$emit("supplierChanged", supplier);
    },
  },

  watch: {
    supplier(supplier) {
      if (supplier) {
        this.supplierModel = supplier;
        this.getSuppliers(null, supplier);
      } else {
        this.supplierModel = null;
        this.getSuppliers();
      }
    },
    suppliersArray(suppliers) {
      if (suppliers?.length) {
        suppliers.map((supplier) => {
          if (!this.supplierModel.includes(supplier?.id?.toString())) {
            this.supplierModel.push(supplier?.id?.toString());
          }
        });
      }
    },
    filterOrganization(filterOrganization) {
      this.getSuppliers();
    },
  },
};
</script>
