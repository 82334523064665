<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <div class="row">
      <!-- Organization -->
      <div class="col-12">
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <organization-selector
            :organization="supplyRule.organization.id"
            :filterable="true"
            :showAll="false"
            :disabled="!!supplyRule.id"
            @organizationChanged="
              (organizationId) => {
                supplyRule.organization.id = organizationId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>
      <div class="col-12 col-md-12" v-show="supplyRule?.organization?.id">
        <base-input
          :label="`${$t('COMMON.LOCATION')}`"
          :placeholder="$t('COMMON.LOCATION')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
        >
          <locations-selector
            :locations="supplyRule.allowedLocations"
            :filterable="true"
            :showAll="false"
            :multiple="true"
            :organization="supplyRule.organization.id"
            @locationsChanged="
              (locations) => {
                supplyRule.allowedLocations = locations;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.allowedLocations" />
      </div>

      <div class="col-12 col-md-6">
        <base-input
          :label="`${$t('COMMON.SUPPLIER')} (*)`"
          :placeholder="$t('COMMON.SUPPLIER')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS)"
        >
          <supplier-selector
            :supplier="supplyRule.supplier?.id"
            :filterable="true"
            :showAll="false"
            :multiple="false"
            :allowNone="true"
            :filterOrganization="supplyRule?.organization?.id"
            @supplierChanged="
              (supplierId) => {
                supplyRule.supplier.id = supplierId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.supplier" />
      </div>

      <div class="col-12 col-md-6">
        <base-input :label="`${$t(`COMMON.WAREHOUSE`)}`">
          <warehouse-selector
            :allowNone="false"
            :warehouse="supplyRule?.warehouse?.id"
            :multiple="false"
            :filterable="true"
            :showAll="false"
            :filterOrganization="supplyRule.organization.id"
            @warehouseChanged="
              (warehouseId) => {
                supplyRule.warehouse.id = warehouseId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.warehouse" />
      </div>

      <div class="col-12">
        <base-input
          :label="`${$t('SUPPLY_RULES.SUPPLY_RULE_NAME')} (*)`"
          :placeholder="$t('SUPPLY_RULES.SUPPLY_RULE_NAME')"
          @change="onFormChanged"
          v-model="supplyRule.name"
        />
        <validation-error :errors="apiValidationErrors.name" />
      </div>

      <div class="col-12 row">
        <div class="col-2">
          <base-input :label="`${$t('COMMON.TYPE')}`">
            <el-select
              :label="$t('COMMON.TYPE')"
              :placeholder="$t('COMMON.TYPE')"
              v-model="supplyRule.phone_type"
              @change="
                (type) => {
                  supplyRule.phone_type = type;
                  onFormChanged();
                }
              "
            >
              <el-option
                v-for="(value, key) in phoneTypesOptions"
                :key="key"
                :value="value"
                :label="$t(`COMMON.PHONE_TYPE_${value}`)"
              />
            </el-select>
          </base-input>
        </div>

        <div class="col-6 col-md-7">
          <base-input :label="`${$t('COMMON.PHONE')}`">
            <phone-number-input
              :phoneNumber="supplyRule.phone"
              @phoneNumberChanged="
                (phone) => {
                  supplyRule.phone = phone;
                  onFormChanged();
                }
              "
              :required="false"
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.phone" />
        </div>

        <div class="col-6 col-md-3">
          <base-input
            :label="`${$t('COMMON.EXTENSION')}`"
            v-model="supplyRule.phone_extension"
            @change="
              () => {
                onFormChanged();
              }
            "
            :inputClasses="'extension-input'"
          />
          <validation-error :errors="apiValidationErrors.phone_extension" />
        </div>
      </div>

      <!-- Other phone numbers -->
      <div class="col-12">
        <base-input :label="`${$t('COMMON.OTHER_PHONES')}`">
          <phone-numbers-selector
            :phoneNumbers="supplyRule.other_phones"
            @phoneNumbersChanged="
              (phoneNumbers) => {
                supplyRule.other_phones = phoneNumbers;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.other_phones" />
      </div>

      <div class="col-12 col-md-6">
        <base-input
          :label="`${$t('COMMON.EMAIL')}`"
          :type="'email'"
          v-model="supplyRule.email"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.email" />
      </div>

      <div class="col-12 col-md-6">
        <base-input
          :label="`${$t('COMMON.CUSTOMER_NUMBER')}`"
          :type="'text'"
          v-model="supplyRule.customer_number"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.customer_number" />
      </div>

      <div class="col-12 col-md-6">
        <base-input
          :label="`${$t('SUPPLY_RULES.SUPPLY_RULE_DELIVERY_DAYS')} (*)`"
          :placeholder="$t('SUPPLY_RULES.SUPPLY_RULE_DELIVERY_DAYS')"
          @change="onFormChanged"
        >
          <el-select
            :multiple="true"
            class="select-primary pagination-select"
            v-model="supplyRule.delivery_days"
            :placeholder="$t('SUPPLY_RULES.SUPPLY_RULE_ORDER_METHOD')"
          >
            <el-option
              class="select-primary"
              v-for="(item, key) in 5"
              :key="key"
              :label="$t(`SUPPLY_RULES.SUPPLY_RULE_DELIVERY_DAY_${item}`)"
              :value="item.toString()"
            >
            </el-option>
          </el-select>
        </base-input>

        <validation-error :errors="apiValidationErrors.delivery_days" />
      </div>
      <div class="col-12 col-md-6">
        <base-input
          :label="`${$t('SUPPLY_RULES.SUPPLY_RULE_ORDER_BEFORE_HOUR')} (*)`"
          :placeholder="$t('SUPPLY_RULES.SUPPLY_RULE_ORDER_BEFORE_HOUR')"
        >
          <flat-picker
            :config="{
              allowInput: true,
              noCalendar: true,
              enableTime: true,
              time_24hr: true,
              type: 'time',
              locale: $flatPickrLocale(),
            }"
            class="form-control datepicker"
            v-model="supplyRule.order_before_hour"
            @on-change="
              () => {
                onFormChanged();
              }
            "
          >
          </flat-picker>
        </base-input>
        <validation-error :errors="apiValidationErrors.order_before_hour" />
      </div>

      <div class="col-12 col-md-6">
        <base-input
          :label="`${$t('SUPPLY_RULES.SUPPLY_RULE_ORDER_METHOD')} (*)`"
          :placeholder="$t('SUPPLY_RULES.SUPPLY_RULE_ORDER_METHOD')"
          @change="onFormChanged"
          v-model="supplyRule.order_method"
        >
          <el-select
            class="select-primary pagination-select"
            v-model="supplyRule.order_method"
            :placeholder="$t('SUPPLY_RULES.SUPPLY_RULE_ORDER_METHOD')"
          >
            <el-option
              class="select-primary"
              v-for="(item, key) in methods"
              :key="key"
              :label="$t(`SUPPLY_RULES.SUPPLY_RULE_ORDER_METHOD_${item}`)"
              :value="item"
            >
            </el-option>
          </el-select>
        </base-input>

        <validation-error :errors="apiValidationErrors.order_method" />
      </div>

      <div class="col-12 col-md-6">
        <base-input
          type="number"
          :label="`${$t('SUPPLY_RULES.SUPPLY_RULE_ORDER_DELAY')} (*)`"
          :placeholder="$t('SUPPLY_RULES.SUPPLY_RULE_ORDER_DELAY')"
          @change="onFormChanged"
          v-model="supplyRule.order_delay"
        />
        <validation-error :errors="apiValidationErrors.order_delay" />
      </div>

      <div
        class="col-12"
        v-if="supplyRule.order_method === METHOD_ONLINE_ORDER"
      >
        <base-input
          type="url"
          :label="`${$t('SUPPLY_RULES.WEBSITE_URL')}`"
          :placeholder="$t('SUPPLY_RULES.WEBSITE_URL')"
          @change="onFormChanged"
          v-model="supplyRule.website"
        >
        </base-input>

        <validation-error :errors="apiValidationErrors.website" />
      </div>

      <div class="col-12 col-md-6">
        <base-input
          type="number"
          :label="`${$t('SUPPLY_RULES.SUPPLY_RULE_MIN_QUANTITY')} (*)`"
          :placeholder="$t('SUPPLY_RULES.SUPPLY_RULE_MIN_QUANTITY')"
          @change="onFormChanged"
          v-model="supplyRule.min_purchase"
        />
        <validation-error :errors="apiValidationErrors.min_purchase" />
      </div>

      <div class="col-12 col-md-6">
        <base-input
          type="number"
          :label="`${$t('SUPPLY_RULES.SUPPLY_RULE_MAX_QUANTITY')} (*)`"
          :placeholder="$t('SUPPLY_RULES.SUPPLY_RULE_MAX_QUANTITY')"
          @change="onFormChanged"
          v-model="supplyRule.max_purchase"
        />
        <validation-error :errors="apiValidationErrors.max_purchase" />
      </div>

      <div class="col-12">
        <base-input
          :label="`${$t('SUPPLY_RULES.EXCERPT')}`"
          :placeholder="$t('SUPPLY_RULES.EXCERPT')"
        >
          <html-editor v-model="supplyRule.excerpt" @change="onFormChanged()">
          </html-editor>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>
    </div>

    <div class="mb-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          supplyRule.id
            ? $t("SUPPLY_RULES.EDIT_SUPPLY_RULE")
            : $t("SUPPLY_RULES.ADD_SUPPLY_RULE")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import WarehouseSelector from "@/components/WarehouseSelector.vue";
import SupplierSelector from "@/components/SupplierSelector.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector";
import { phoneTypesOptions } from "@/constants/common";

import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { METHOD_ONLINE_ORDER, METHODS } from "@/constants/supplyRules";
import moment from "moment";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    flatPicker,
    ValidationError,
    OrganizationSelector,
    PhoneNumbersSelector,
    HtmlEditor,
    LocationsSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    SupplierSelector,
    WarehouseSelector,
    PhoneNumberInput,
  },

  mixins: [formMixin],

  props: ["supplyRuleData", "formErrors", "loading"],

  data() {
    const defaultTime = moment().format("HH:mm");
    let supplyRule = this.$fillUserOrganizationData(this.supplyRuleData);
    const order_before_hour = moment(defaultTime, "H:i:s").format("HH:mm");

    return {
      supplyRule: {
        ...supplyRule,
        order_before_hour: order_before_hour,
      },
      methods: METHODS,
      METHOD_ONLINE_ORDER,
      phoneTypesOptions,
    };
  },

  created() {},

  mounted() {
    this.supplyRule = {
      ...this.supplyRule,
      ...cloneDeep(this.supplyRuleData),
    };
  },

  methods: {
    async handleSubmit() {
      let supplyRuleData = cloneDeep(this.supplyRule);
      this.$emit("supplyRuleSubmitted", supplyRuleData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    supplyRuleData(supplyRuleData) {
      console.log("supplyRuleData", supplyRuleData);
      if (supplyRuleData) {
        this.supplyRule = {
          ...this.supplyRule,
          ...cloneDeep(supplyRuleData),
        };
      }
    },
  },
};
</script>
