export default {
  type: "supply-rules",
  name: "",
  order_before_hour: null,
  order_method: "",
  phone_type: "",
  phone_extension: "",
  order_delay: null,
  min_purchase: null,
  max_purchase: null,
  delivery_days: [],
  other_phones: [],
  relationshipNames: [
    "organization",
    "allowedLocations",
    "supplier",
    "warehouse",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  warehouse: {
    type: "warehouses",
    id: null,
  },
  supplier: {
    type: "suppliers",
    id: null,
  },
  allowedLocations: [],
};
